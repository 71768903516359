import React from "react";
import "./biografia.css";
import { useLenguaje } from "../../hook/UseLenguaje";

export default function Biografia() {

  const {t} = useLenguaje();

  return (
    <div className="biography">
        <h2>{t("item9")}</h2>
      <p>
      {t("item10")}
        <br></br>
        <br></br>
        Hoy en día, mi enfoque está en fusionar diseño y tecnología para crear
        experiencias visuales y funcionales excepcionales. Disfruto
        especialmente de trabajar en proyectos donde el diseño estético se
        encuentra con la ingeniería de software, asegurando que cada detalle
        esté perfectamente ejecutado. En mi tiempo libre, me apasiona explorar
        nuevas técnicas en diseño y desarrollo, y siempre estoy buscando nuevas
        formas de llevar mis habilidades al siguiente nivel.
        <br></br>
        <br></br>
        Cuando no estoy trabajando, me encontrarás explorando nuevas tendencias en diseño, o disfrutando de
        tiempo con mi familia y mis pasatiempos creativos.
      </p>
    </div>
  );
}
