import React from 'react'
import Admin from './administrador/Admin'
import { Outlet } from 'react-router-dom'


export default function AdminLayout() {
  return (
    <div>
     <Admin />
     <Outlet />
    </div>
  )
}
