import { createContext, useState } from "react";

//1 Creamos el contexto

export const LenguajeContext = createContext();

const translations = {
  es: {
    nombre: "Nicolas Gunaris",
    item1: "Programador FullStack",
    item2: "Diseñador Grafico",
    item3: "Representacion 3D",
    item4: "'Innovación en cada píxel y línea de código'",
    item5: "Habilidades Tecnicas",
    item6: "Codigo",
    item7: "3D",
    item8: "Diseño Grafico",
    item9: "Sobre Mi",
    item10:
      "Hace 20 años comencé mi viaje como diseñador gráfico, y en los últimos 10 años me he adentrado en el mundo del renderizado 3D. Más recientemente, en los últimos 3 años, me he sumergido en la programación, combinando mis habilidades en diseño con el desarrollo web. Hoy en día, mi enfoque está en fusionar diseño y tecnología para crear experiencias visuales y funcionales excepcionales. Disfruto especialmente de trabajar en proyectos donde el diseño estético se encuentra con la ingeniería de software, asegurando que cada detalle esté perfectamente ejecutado. En mi tiempo libre, me apasiona explorar nuevas técnicas en diseño y desarrollo, y siempre estoy buscando nuevas formas de llevar mis habilidades al siguiente nivel. Cuando no estoy trabajando, me encontrarás explorando nuevas tendencias en diseño, o disfrutando de tiempo con mi familia y mis pasatiempos creativos.",
    item11: "Proyectos",
    item12: "Renders",
    item13:
      "Visualizaciones 3D de alta calidad que transforman ideas en imágenes realistas, capturando cada detalle y perspectiva.",
    item14: "Diseño Grafico",
    item15:
      "Creaciones visuales que comunican mensajes y conceptos de manera efectiva a través de diseño y estética.",
    item16: "Codigo",
    item17:
      "Desarrollo de soluciones tecnológicas a través de programación, enfocadas en la eficiencia y la innovación.",
    item18:
      "Diseñado libremente y codificado en",
      item19: "por un servidor. Creado con ",
      item20: "y ",
  },

  en: {
    nombre: "Nicolas Gunaris",
    item1: "FullStack Developer ",
    item2: "Graphic Designer",
    item3: "Rrender 3D",
    item4: "'Innovation in Every Pixel and Line of Code'",
    item5: "Technical Skills",
    item6: "Code",
    item7: "3D",
    item8: "Graphic Designer",
    item9: "About me",
    item10:
      "20 years ago I began my journey as a graphic designer, and over the past 10 years I have delved into the world of 3D rendering. More recently, over the past 3 years, I have immersed myself in programming, combining my design skills with web development. Nowadays, my focus is on merging design and technology to create exceptional visual and functional experiences. I especially enjoy working on projects where aesthetic design meets software engineering, ensuring every detail is perfectly executed. In my spare time, I am passionate about exploring new techniques in design and development, and I am always looking for new ways to take my skills to the next level. When I am not working, you will find me exploring new trends in design, or enjoying time with my family and creative hobbies.",
    item11: "Proyects",
    item12: "Renders",
    item13:
      "High-quality 3D visualizations transform ideas into realistic images, capturing every detail and perspective.",
    item14: "Graphic Designer",
    item15:
      "Visual creations that communicate messages and concepts effectively through design and aesthetics.",
    item16: "Code",
    item17:
      "Development of technological solutions through programming, focused on efficiency and innovation.",
    item18:
      "Freely designed and coded in",
      item19: "by a server. Created with ",
      item20: "and ",
  },
};
//2 crear provider

export function LenguajeProvider({ children }) {
  //Setear el lenguaje
  const [lenguaje, setLenguaje] = useState("es");
  //funcion para cambiar el lenguaje
  const toogleLenguaje = () => {
    setLenguaje((prevLang) => (prevLang === "es" ? "en" : "es"));
  };

  //funcion para obtener las traducciones
  const t = (key) => {
    return translations[lenguaje][key] || key;
  };

  return (
    <LenguajeContext.Provider value={{ lenguaje, toogleLenguaje, t }}>
      {children}
    </LenguajeContext.Provider>
  );
}
