import React from "react";
import "./admin.css";
import FotoPerfil from "../componentesAdmin/fotoPerfil/FotoPerfil";
import FotosRenders from "../componentesAdmin/fotosRenders/FotosRenders";
import FotoDiseño from "../componentesAdmin/fotosDiseño/FotoDiseño";


export default function Admin() {
  return (
    <div className="admin">
    <FotoPerfil />
    <FotosRenders />
    <FotoDiseño />
    </div>
  );
}
