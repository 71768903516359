import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { LenguajeProvider } from "./context/lenguaje";
import { AdminProvider } from "./context/adminContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AdminProvider>
  <LenguajeProvider>
    <App />
  </LenguajeProvider>
  </AdminProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
