import React from "react";
import "./footer.css";
import { useLenguaje } from "../../hook/UseLenguaje";

export default function Footer() {

  const {t} = useLenguaje();

  return (
    <div className="footer">
      <p>
       {t("item18")}{" "}
        <a
          href="https://code.visualstudio.com/"
          target="_blank"
          rel="nooper noreferrer"
        >
          Visual Studio Code
        </a>{" "}
        {t("item19")}
        <a
          href="https://legacy.reactjs.org/"
          target="_blank"
          rel="nooper noreferrer"
        >
          React
        </a>
        ,{" "}
        <a
          href="https://www.javascript.com/"
          target="_blank"
          rel="nooper noreferrer"
        >
          JavaScript
        </a>
        ,{" "}
        <a href="https://nodejs.org/en" target="_blank" rel="nooper noreferrer">
          Node.js
        </a>
        ,{" "}
        <a
          href="https://expressjs.com/"
          target="_blank"
          rel="nooper noreferrer"
        >
          Express
        </a>{" "}
        {t("item20")}
        <a
          href="https://www.mysql.com/"
          target="_blank"
          rel="nooper noreferrer"
        >
          MySql
        </a>
      </p>
    </div>
  );
}
