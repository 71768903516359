import React, {useState, useEffect} from 'react'
import { rutaAPI } from '../config';
import './diseño.css';
import HeaderSmall from '../components/headerSmall/HeaderSmall';

export default function Diseño() {
 
  //1 - Configurar los hooks
  const [galeria, setGaleria] = useState([]);
  const [imagenPrincipal, setImagenPrincipal] = useState();
  const [mensaje, setMensaje] = useState(null);

  //2 - Funcion para mostrar los datos con tetch
  const verFoto = async () => {
    try {
        const response = await getData();

        if (!response.ok) {
            throw new Error(`Error en la API: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();

        if (!data || !Array.isArray(data.docs)) {
            throw new Error("La respuesta de la API no contiene los datos esperados.");
        }

        if (data.docs.length > 0) {
            const imagenes = data.docs.map(doc => `${rutaAPI}/verDiseno/${doc.id}`);
            setGaleria(imagenes);
            setMensaje(null);  // Borrar el mensaje si hay imágenes
        } else {
            setMensaje("No hay imágenes disponibles.");
        }
    } catch (error) {
        console.error("Error al obtener los datos", error);
        setMensaje("Ocurrió un error al obtener las imágenes.");
    }
  };
  useEffect(() => {
    verFoto();
  }, []);


  return (
    <div className="renders">
      <HeaderSmall />
      <div className="encabezado">
        <h2>Mis Diseños</h2>
      </div>
      <div className="gallery">
        {galeria.map((foto, index) => (
          <div key={index} className="box">
            <img src={foto} alt="/photo"></img>
          </div>
        ))}
      </div>
    </div>
  );
}


/*PETICION GET PARA GALERIA*/
const getData = async () => {
  try {
    const url = `${rutaAPI}/mostrarDiseno`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Error al obtener los datos");
    }
    return response;
  } catch (err) {
    return err;
  }
}
