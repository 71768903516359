import React, { useEffect, useState } from "react";
import "./fotoPerfil.css";
import { rutaAPI } from "../../../config";

export default function FotoPerfil() {
  const [foto, setFoto] = useState([]);
  const [newFoto, setNewFoto] = useState(null);
  const [image, setImage] = useState(null);
  const [imagePreviewEdit, setImagePreviewEdit] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [imagenPreview, setImagenPreview] = useState(null);

  // Obtener productos desde la API
  const url = `${rutaAPI}/api/mostrarPerfil`;
  const token = localStorage.getItem("ACCESS_TOKEN");
  const showData = async () => {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log(data.docs[0]);
    setFoto(data.docs);
  };

  // Función para manejar la selección de imagen
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagenPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImage(null);
      setImagenPreview(null);
    }
  };

  // Agregar nuevo producto a la API
  const addProduct = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("imagen", image);

    try {
      const response = await fetch(`${rutaAPI}/api/agregarPerfil`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`, // Incluye el token
        },
      });
      if (!response.ok) {
        throw new Error("no funciona la api");
      }
      showData();
      setImage(null);
      setImagenPreview(null);
    } catch (error) {
      console.error("error adding prodcut", error);
    }
  };

  // Eliminar producto de la API
  const deleteProduct = async (id) => {
    try {
      await fetch(`${rutaAPI}/api/borrarPerfil/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`, // Incluye el token
        },
      });
      showData();
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  // Función para manejar la selección de imagen para edición
  const handleImageChangeEdit = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewEdit(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Función para actualizar la imagen en la API
  const updateProduct = async (e) => {
    e.preventDefault();
    if (!selectedProduct || !imagePreviewEdit) {
      console.error("Debe seleccionar una imagen para actualizar.");
      return;
    }

    const formData = new FormData();
    formData.append("imagen", image);

    try {
      const response = await fetch(`${rutaAPI}/api/editarPerfil/${selectedProduct.id}`, {
        method: "PUT",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`, // Incluye el token
        },
      });
      if (!response.ok) {
        throw new Error("Error al actualizar la imagen.");
      }
      showData();
      setImage(null); // Limpiar imagen y vista previa
      setImagePreviewEdit(null);
    } catch (error) {
      console.error("Error al actualizar la imagen", error);
    }
  };

  // Función para manejar la selección de producto
  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setImagePreviewEdit(null); // Limpiar vista previa de la imagen de edición al seleccionar un nuevo producto
  };

  useEffect(() => {
    showData();
  }, []);

  return (
    <div className="perfil">
      <div className="boxForm">
        <form className="formPerfil" onSubmit={addProduct}>
          <h2>Agregar Foto de perfil</h2>
          <input
            type="file"
            className="formInput"
            onChange={handleImageChange}
          ></input>
          <button className="btnAgregar" type="submit">
            Agregar Producto
          </button>
          {imagenPreview ? (
            <div className="boxMuestra">
              <img
                className="muestra"
                src={imagenPreview}
                alt="Vista Perfil"
              ></img>
            </div>
          ) : null}
        </form>


        {foto.length > 0 ? (<form className="formPerfil" onSubmit={updateProduct}>
          <h2>Editar Foto de perfil</h2>
          <ul className="listaPerfil">
            {foto.map((productos) => (
              <li key={productos.id}>
                <input
                  type="file"
                  className="formInput"
                  onChange={handleImageChangeEdit}
                  onClick={() => handleProductSelect(productos)}
                ></input>
                {productos.ruta && !imagePreviewEdit && (
                  <div className="boxMuestra">
                    <img
                      className="muestra"
                      src={`${rutaAPI}/${productos.ruta}`}
                      alt={productos.nombre}
                    ></img>
                  </div>
                )}{imagePreviewEdit && (
                  <div className="boxMuestra">
                    <img
                      className="muestra"
                      src={imagePreviewEdit}
                      alt="Vista Perfil"
                    ></img>
                  </div>
                )}
                <div>
                  <button className="btnAgregar" type="submit">
                    Actualizar
                  </button>
                  <button
                    className="btnEliminar"
                    onClick={() => deleteProduct(productos.id)}
                  >
                    Eliminar
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </form>): null}
        
      </div>
    </div>
  );
}
