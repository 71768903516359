import React, { useState, useEffect } from "react";
import "./cursorlight.css";

export default function CursorLight() {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="cursor-light">
      <div
        className="light"
        style={{ top: position.y - 150, left: position.x - 150 }}
      ></div>
    </div>
  );
}
