import React, { useState, useEffect, useRef } from "react";
import { rutaAPI } from "../../../config";
import "./crearAdmin.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faKey } from "@fortawesome/free-solid-svg-icons";

export default function CrearAdmin() {
  /*USO REFERENCIAS PARA MANEJAR EL DOM*/
  const inputRef = useRef(null);
  const indicatorsRef = useRef(null);
  /*-------------------------------*/

  const [administrador, CrearAdministrador] = useState({
    nombre: "",
    email: "",
    password: "",
    codigo: "",
  });
  /*VARIABLE DE ERRORES*/
  const [errorMessage, setErrorMessage] = useState(null);
  /*VARIABLE DE OK*/
  const [okMessage, setOkMessage] = useState(null);

  const cambiarFormPost = (e) => {
    const { name, value } = e.target;
    CrearAdministrador((prevstate) => ({
      ...prevstate,
      [name]: value,
    }));
  };

  /*================================
   FUNCION PARA VER INFO DE PASSWORD
   ==================================*/
  const datos = () => {
    if (indicatorsRef.current) {
      indicatorsRef.current.style.display = "flex";
    }
  };

  const handleOutside = (event) => {
    if (
      inputRef.current &&
      indicatorsRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      indicatorsRef.current.style.display = "none";
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutside);
    return () => {
      document.removeEventListener("click", handleOutside);
    };
  }, []);
  /*==========================
      EJECUTAMOS EL SUBMIT
       ==============================*/

  const submitPost = async (e) => {
    e.preventDefault();
    const { nombre, email, password, codigo } = administrador;

    if (codigo === "") {
      setErrorMessage("Pon el codigo por favor");
      return;
    }

    if (nombre === "") {
      setErrorMessage("Pon tu nombre");
      return;
    }
    const expAdministrador = /(?=.*[A-Za-z]).{2,6}/;
    if (!expAdministrador.test(nombre)) {
      setErrorMessage("Nombre con errores");
      return;
    }

    if (password === "") {
      setErrorMessage("coloca tu password");
      return;
    }
    const expPassword = /(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}/;
    if (!expPassword.test(password)) {
      setErrorMessage("Tu contraseña no cumple los requerimientos");
      return;
    }

    if (email === "") {
      setErrorMessage("Completa el cambo de email");
      return;
    }
    const expEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!expEmail.test(email)) {
      setErrorMessage("Formato de mail no valido");
    }

   
    // Ejecutamos el servicio POST
    const result = await crearAdmin(administrador);
    if (result?.error) {
      setErrorMessage(result.message || "Error al crear el administrador");
    } else {
      setOkMessage("Administrador creado exitosamente");
      setTimeout(() => {
        window.location.href = "/";
      }, 3000);
    }
  };

  return (
    <div className="boxCrearAdmin">
      <div className="crearAdmin" style={{ width: "20rem" }}>
        <div className="adminUser">
          <h1>Crear Administrador</h1>
        </div>
        <div className="crearAdminForm">
          <form onChange={cambiarFormPost} onSubmit={submitPost}>
            <div className="input-group">
              <div className="input-group-append">
                <FontAwesomeIcon icon={faUser} />
              </div>
              <input
                className="form-control"
                placeholder="Nombre"
                type="text"
                name="nombre"
                id="name"
                minLength="2"
                maxLength="12"
                pattern="(?=.*[A-Za-z]).{2,12}"
                required
              ></input>
            </div>
            <div className="input-group">
              <div className="input-group-append">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <input
                className="form-control"
                placeholder="Email"
                name="email"
                type="text"
                minLength="8"
                required
              ></input>
            </div>
            <div className="input-group d-flex">
              <div className="input-group-append">
                <FontAwesomeIcon icon={faKey} />
              </div>
              <input
                className="form-control"
                placeholder="Contraseña"
                ref={inputRef}
                name="password"
                type="password"
                minLength="8"
                pattern="(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}"
                onFocus={datos}
                required
              ></input>
            </div>
            <div className="input-group">
              <div className="input-group-append">
                <FontAwesomeIcon icon={faKey} />
              </div>
              <input
                className="form-control"
                placeholder="Código de Seguridad"
                name="codigo"
                type="password"
                required
              />
            </div>
            <div className="indicators" ref={indicatorsRef}>
              1 letra minuscula <br></br> 1 letra mayuscula <br></br> 1 al menos
              1 digito <br></br> Al menos 8 caracteres.
            </div>
            <div className="input-group mb-3">
              {errorMessage ? (
                <div className="alert alert-danger">{errorMessage}</div>
              ) : null}
            </div>
            <div className="input-group mb-3">
              {okMessage ? (
                <div className="alert alert-success">{okMessage}</div>
              ) : null}
            </div>
            <div id="botonBox">
              <button type="submit" className="botonAdmin" id="admin">
                {" "}
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}


const crearAdmin = async (data) => {
  const url = `${rutaAPI}/api/agregarAdmin`;
  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await fetch(url, params);
    if (!response.ok) throw new Error("Tienes un Error");
    const result = await response.json();
    console.log("Resultado de la API:", result);
    return result;
  } catch (err) {
    console.error("Error en la petición:", err);
    return { error: true, message: err.message };
  }
};