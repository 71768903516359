import { createContext, useEffect, useState } from "react";

//Creamos el contexto
export const AdminContext = createContext();

//Proveedor del contexto
export function AdminProvider({ children }) {
  const [usuarioLogueado, setUsuarioLogueado] = useState("");
  const [adminLogueado, setAdminLogueado] = useState("");

  useEffect(() => {
    const usuario = localStorage.getItem("USUARIO");
    if (usuario) {
      setUsuarioLogueado(usuario);
    }

    const administrador = localStorage.getItem("ADMINISTRADOR");
    if (administrador) {
      setAdminLogueado(administrador);
    }
  }, []);

  return (
    <AdminContext.Provider
      value={{
        usuarioLogueado,
        setUsuarioLogueado,
        adminLogueado,
        setAdminLogueado,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
}
