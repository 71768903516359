import React from "react";
import "./proyectos.css";
import { useLenguaje } from "../../hook/UseLenguaje";

import render from "../../img/renders.png";
import dg from "../../img/dg.png";
import codeImg from "../../img/codeImg.png";

export default function Proyectos() {
  const { t } = useLenguaje();

  return (
    <div className="proyectos">
      <h2>{t("item11")}</h2>
      <div className="card">
        <div className="left-column">
          <a href="/renders">
            <img src={render} alt="/#"></img>
          </a>
        </div>
        <div className="right-column">
          <h4>{t("item12")}</h4>
          <p>{t("item13")}</p>
        </div>
      </div>
      <div className="card">
        <div className="left-column">
          <a href="/diseño">
            <img src={dg} alt="/#"></img>
          </a>
        </div>
        <div className="right-column">
          <h4>{t("item14")}</h4>
          <p>{t("item15")}</p>
        </div>
      </div>
      <div className="card">
        <div className="left-column">
          <img src={codeImg} alt="/#"></img>
        </div>
        <div className="right-column">
          <h4>{t("item16")}</h4>
          <p>{t("item17")}</p>
        </div>
      </div>
    </div>
  );
}
