import React from "react";
import "./habilidades.css";

//imgs
import js from "../../img/js.png";
import html from "../../img/html.png";
import css from "../../img/css.png";
import node from "../../img/node.png";
import react from "../../img/react.png";
import mongo from "../../img/mongo.png";
import mysql from "../../img/mysql.png";
import sketchup from "../../img/sketchup.png";
import lumion from "../../img/lumion.png";
import blender from "../../img/blender.jpg";
import photoshop from "../../img/photoshop.png";
import corel from "../../img/corel.png";
import indesign from "../../img/indesign.png";
import ilustrator from "../../img/ilustrator.png";
import { useLenguaje } from "../../hook/UseLenguaje";

export default function Habilidades() {

  const {t} = useLenguaje();

  return (
    <div className="habilidades">
      <h2>{t("item5")}</h2>

      <div className="habContent">
        <div>{t("item6")}</div>
        <ul className="lenguajes">
          <li>
            <a
              href="https://www.javascript.com/"
              target="_blank"
              rel="nooper noreferrer"
            >
              <img src={js} className="logosPr"></img>
            </a>
          </li>
          <li>
            <a
              href="https://lenguajehtml.com/"
              target="_blank"
              rel="nooper noreferrer"
            >
              <img src={html} className="logosPr"></img>
            </a>
          </li>
          <li>
            {" "}
            <a
              href="https://lenguajecss.com/"
              target="_blank"
              rel="nooper noreferrer"
            >
              <img src={css} className="logosPr"></img>
            </a>
          </li>
          <li>
            {" "}
            <a
              href="https://nodejs.org/en"
              target="_blank"
              rel="nooper noreferrer"
            >
              <img src={node} className="logosPr"></img>
            </a>
          </li>
          <li>
            <a
              href="https://legacy.reactjs.org/"
              target="_blank"
              rel="nooper noreferrer"
            >
              <img src={react} className="logosPr"></img>
            </a>
          </li>
          <li>
            <a
              href="https://www.mongodb.com/"
              target="_blank"
              rel="nooper noreferrer"
            >
              <img src={mongo} className="logosBs"></img>
            </a>
          </li>
          <li>
            <a
              href="https://www.mysql.com/"
              target="_blank"
              rel="nooper noreferrer"
            >
              <img src={mysql} className="logosBs"></img>
            </a>
          </li>
        </ul>
        <div>{t("item7")}</div>
        <ul className="rendering">
          <li>
            <a
              href="https://www.sketchup.com/es"
              target="_blank"
              rel="nooper noreferrer"
            >
              <img src={sketchup} className="logosPr"></img>
            </a>
          </li>
          <li>
            <a
              href="https://www.blender.org/"
              target="_blank"
              rel="nooper noreferrer"
            >
              <img src={blender} className="logosPr"></img>
            </a>
          </li>
          <li>
            <a
              href="https://lumion.es/"
              target="_blank"
              rel="nooper noreferrer"
            >
              <img src={lumion} className="logosPr"></img>
            </a>
          </li>
        </ul>
        <div>{t("item8")}</div>
        <ul className="graphic">
          <li>
            <a
              href="https://www.coreldraw.com/en/product/coreldraw"
              target="_blank"
              rel="nooper noreferrer"
            >
              <img src={corel} className="logosPr"></img>
            </a>
          </li>
          <li>
            <a
              href="https://www.adobe.com/es/products/photoshop.html"
              target="_blank"
              rel="nooper noreferrer"
            >
              <img src={photoshop} className="logosPr"></img>
            </a>
          </li>
          <li>
            <a
              href="https://www.adobe.com/es/products/illustrator.html"
              target="_blank"
              rel="nooper noreferrer"
            >
              <img src={ilustrator} className="logosPr"></img>
            </a>
          </li>
          <li>
            <a
              href="https://www.adobe.com/es/products/indesign.html"
              target="_blank"
              rel="nooper noreferrer"
            >
              <img src={indesign} className="logosPr"></img>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
