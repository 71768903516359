import React, { useEffect, useState } from "react";
import "./header.css";
import { rutaAPI } from "../../config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import perfil from "../../img/perfil.JPG";
import { useLenguaje } from "../../hook/UseLenguaje";

export default function Header() {
  //FUNCION PARA COPAR EL TEXTO
  const copy = () => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        console.log("Texto copiado al portapapeles");
      })
      .catch((Err) => {
        console.log("Erro al copiar el texto", Err);
      });
  };

  //==============HOOK========================
  const { lenguaje, toogleLenguaje } = useLenguaje();
  const { t } = useLenguaje();

  //1 - Configurar los hooks
  const [galeria, setGaleria] = useState([]);
  const [imagenPrincipal, setImagenPrincipal] = useState(perfil);

  //2 - Funcion para mostrar los datos con tetch
  const verFoto = async () => {
    try {
      const response = await getData();
      const data = await response.json();

      if (!data || !Array.isArray(data.docs)) {
        throw new Error(
          "La respuesta de la api no contiene los datos esperados"
        );
      }
      // Asignar la primera imagen de la galería (o cualquiera que desees).
      if(data.docs.length> 0){
        setGaleria(data.docs);
        setImagenPrincipal(`${rutaAPI}/api/verPerfil/${data.docs[0].id}`);
      }
    } catch (error) {
      console.error("error la obtener los datos", error);
    }
  };
  useEffect(() => {
    verFoto();
  }, []);

  //===========CONSTANTES=====================
  const email = "ngunaris@gmail.com";

  return (
    <div className="header">
      <div className="idioma">
        {" "}
        Idioma
        <button
          onClick={toogleLenguaje}
          className={`lenguaje ${lenguaje === "en" ? "lengUsa" : ""}`}
        >
          {lenguaje === "es" ? (
            <div className="es">
              <i>ES</i>
            </div>
          ) : (
            <div className="en">
              <i>EN</i>
            </div>
          )}
        </button>
      </div>
      <div className="presentation">
        <div className="contentImg">
          <img src={imagenPrincipal} alt="mypicture"></img>
        </div>
        <h1>{t("nombre")}</h1>
        <div className="profesiones">
          <h3>{t("item1")}</h3>/<h3>{t("item3")}</h3>/<h3>{t("item2")}</h3>
        </div>
        <div className="slogan">{t("item4")}</div>
        <div className="datos">
          {email}
          <i onClick={copy} className="icon">
            <FontAwesomeIcon icon={faCopy} />
          </i>
        </div>
      </div>
    </div>
  );
}

/*PETICION GET PARA GALERIA*/
const getData = async () => {
  try {
    const url = `${rutaAPI}/api/mostrarPerfil`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Error al obtener los datos");
    }
    return response;
  } catch (err) {
    return err;
  }
};
