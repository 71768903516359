import logo from "./logo.svg";
import React, { useContext, useEffect, useState } from "react";
import { AdminProvider, AdminContext } from "./context/adminContext";
import AdminLayout from "./AdminLayout/AdminLayout";
import Admin from "./AdminLayout/administrador/Admin";
import Login from "./AdminLayout/administrador/login/Login";

import "./App.css";
import Home from "./Home/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CursorLight from "./cursosLight/CursorLight";
import { LenguajeProvider } from "./context/lenguaje";

import Renders from "./renders/Renders";
import Diseño from "./diseño/Diseño";
import CrearAdmin from "./AdminLayout/administrador/crearAdmin/CrearAdmin";
function App() {
  /*==========ESTADO DE USUARIO==========*/
  const { adminLogueado, setAdminLogueaod } = useContext(AdminContext);

  return (
    <AdminProvider>
      <LenguajeProvider>
        <div className="App">
          <Router>
            <Routes>
              <Route
                path="admin"
                element={adminLogueado ? <AdminLayout /> : <Login />}
              ></Route>
              <Route exact path="/crearAdmin" element={<CrearAdmin />} />
              <Route index element={<Home />} />
              <Route exact path="/renders" element={<Renders />} />
              <Route exact path="/diseño" element={<Diseño />} />
            </Routes>
          </Router>
        </div>
      </LenguajeProvider>
    </AdminProvider>
  );
}

export default App;
