import React, { useEffect, useState } from "react";
import "./renders.css";
import HeaderSmall from "../components/headerSmall/HeaderSmall";
import { rutaAPI } from "../config";

export default function Renders() {

  //1 - Configurar los hooks
  const [galeria, setGaleria] = useState([]);
  const [imagenPrincipal, setImagenPrincipal] = useState();

  //2 - Funcion para mostrar los datos con tetch
  const verFoto = async () => {
    try {
      const response = await getData();
      const data = await response.json();

      if (!data || !Array.isArray(data.docs)) {
        throw new Error(
          "La respuesta de la api no contiene los datos esperados"
        );
      }
      // Asignar la primera imagen de la galería (o cualquiera que desees).
      if(data.docs.length> 0){
        const imagenes = data.docs.map(doc => `${rutaAPI}/api/verPic/${doc.id}`);
        setGaleria(imagenes);
        
      }
    } catch (error) {
      console.error("error la obtener los datos", error);
    }
  };
  useEffect(() => {
    verFoto();
  }, []);


  return (
    <div className="renders">
      <HeaderSmall />
      <div className="encabezado">
        <h2>Mis Renders</h2>
      </div>
      <div className="gallery">
        {galeria.map((foto, index) => (
          <div key={index} className="box">
            <img src={foto} alt="/photo"></img>
          </div>
        ))}
      </div>
    </div>
  );
}


/*PETICION GET PARA GALERIA*/
const getData = async () => {
  try {
    const url = `${rutaAPI}/api/mostrarPic`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Error al obtener los datos");
    }
    return response;
  } catch (err) {
    return err;
  }
};
